import * as React from "react"
import { Pmono, H4, SpanTitle } from "../styles/Primitives"
import { base } from "../styles/ColorStyles"
import styled from "styled-components"

const FlexWrapper = styled.div`
  display: flex;
  /* flex-direction: row-reverse; */
  /* justify-content: space-between; */
  align-items: center;
  align-content: center;
  /* border-bottom: 1px dashed ${base.border}; */
  transition: all 0.15s ease-in-out;
  padding: 1rem 0.5rem;

  :hover {
    transition: all 0.3s ease-in-out;
    background: ${base.hover};
  }



  @media (max-width: 600px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    }
`

const DateTitle = styled(SpanTitle)`
width: 160px;
`

const SqLink = styled.a`
  text-decoration: none;
  transition: all 0.30s ease-in-out;
  text-align: left;
  :hover {
    
    text-decoration: none;
     transition: all 0.5s ease-in-out;
    ${H4} {
      color: ${base.actiondark};
    }
    ${DateTitle} {
      color: ${base.actiondark};
    }
    ${Pmono} {
      color: ${base.actiondark};
    }
  }
`

const Wrapper = styled.div`
   border-top: 1px dotted ${base.border};

  :first-child {
    border: none;
}
`



export default function ListItem(props) {
  return (
    <Wrapper>
      <SqLink href={props.Link} target="_blank" >
        <FlexWrapper>
          <DateTitle>
            {props.Subtitle} 
          </DateTitle>
          <H4>
            {props.Title}
          </H4>  
         
        </FlexWrapper>
      </SqLink>
      </Wrapper>
  )
}
