import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutPage from "../components/about/about"

const About = () => (
  <Layout>
    <Seo title="About | Atharva Patil" />
    <AboutPage />
  </Layout>
)

export default About
