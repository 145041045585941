import * as React from "react"
import styled from "styled-components"
import { P, A, H1, H5, H3 } from "../styles/Primitives"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import ListItem from "./ListItem"

export default function AboutPage() {
  const imgdata = useStaticQuery(graphql`
    query AtharvaImage {
      atharva: file(relativePath: { eq: "about/atharva.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const image = getImage(imgdata.atharva)

  return (
    <>
      <Section>
           <ImageWrapper> 
            <GatsbyImage
              image={image}
              alt="Atharva Patil"
            />
          </ImageWrapper>

          <TextContent>
            <H3>Atharva Patil, Product Designer living in Seattle</H3>
            {/* <P>
              Hi, I'm Atharva, commonly known by my last name Patil. I'm a
              Product Designer from India currently living in Seattle.
            </P> */}

            <P>

              Currently I'm working as a Product Designer at  <ALink href="https://labelbox.com/?ref=patil" target="_blank">Labelbox</ALink>, an AI-assisted labeling, model training & diagnostics product suite.
              
              Previously, I led design at{" "}
              <ALink href="https://www.atlasai.co/?ref=patil" target="_blank">
                Atlas AI
              </ALink>{" "}
              creating demand intelligence tools for organizations to
              operationalize their on-ground efforts more
              effectively. I worked on designing advanced flight
              search experiences at{" "}
              <ALink href="https://kayak.com" target="_blank" rel="noreferrer">
                Kayak
              </ALink>{" "}
              & the driver-partners apps at{" "}
              <ALink
                href="https://olacabs.com"
                target="_blank"
                rel="noreferrer"
              >
                Olacabs
              </ALink>
              , crafting experiences for the next billion users across Southeast
              Asia.
            </P>

            <P>
              I like to embody an interdisciplinary philosophy to my craft,
              trying to break free from myopic barriers set by silos of
              disciplines. This curiosity led me to learn web development and
              hardware prototyping that I use both in a professional setting to
              collaborate with colleagues and bring my ideas to life.
            </P>

            <P>
              After hours you can find me snuggled in a book, exploring Seattle, hiking, or engaged in a pointless debate with anyone willing. I'm always interested in new ideas and perspectives. Thanks for stopping by.
            </P>
          </TextContent>

         
       
      </Section>

      <SectionTwo>
        <ItemThreeColumn>
          <SecTitleWrapper>
            <H5>Experience</H5>
          </SecTitleWrapper>
          <TextContent>
            <ListItem    
              Title="Labelbox" 
              Subtitle="2022-Present" 
              Link="https://labelbox.com/" 
            />
            <ListItem    
              Title="Atlas AI" 
              Subtitle="2020-22" 
              Link="https://atlasai.co/" 
            />
            <ListItem    
              Title="Kayak" 
              Subtitle="2019" 
              Link="https://kayak.com/" 
            />
            <ListItem    
              Title="Olacabs" 
              Subtitle="2016-18" 
              Link="https://olacabs.com/" 
            />
            <ListItem    
              Title="MagicX" 
              Subtitle="2015-16" 
              Link="https://www.linkedin.com/company/magictiger/" 
            />
             <ListItem    
              Title="Cubeit" 
              Subtitle="2014-15" 
              Link="https://www.linkedin.com/company/cubeit-io/about/" 
            />
          </TextContent>
        </ItemThreeColumn>  
      </SectionTwo>

      <SectionTwo>
        <ItemThreeColumn>
          <SecTitleWrapper>
            <H5>Speaking</H5>
          </SecTitleWrapper>

          <TextContent>
            <ListItem    
              Title="Generative AI topic panelist | IXDA Seattle" 
              Subtitle="2023" 
              Link="https://www.linkedin.com/events/ixdaseattle-movieclub-topgenera7128538217615659008/about/" 
            />
            <ListItem    
              Title="How to design for AI? | Growthschool" 
              Subtitle="2023" 
              Link="https://growthschool.io/" 
            />
            <ListItem    
              Title="The Centaur Hypothesis | ITP Thesis " 
              Subtitle="2020" 
              Link="https://vimeo.com/425262774" 
            />
            <ListItem    
              Title="My friend is a vegetarian for a better AI | T4SG" 
              Subtitle="2020" 
              Link="https://twitter.com/shiffman/status/1223639606203244544" 
            />
            <ListItem    
              Title="Punchy | ITP Winter show" 
              Subtitle="2019" 
              Link="https://youtu.be/11M3RLmayz8?t=249" 
            />
            <ListItem    
              Title="The Mystery Box | ITP Winter show" 
              Subtitle="2018" 
              Link="https://www.youtube.com/watch?v=02z_yyHAm-U&t=680" 
            />
          </TextContent>
        </ItemThreeColumn>  
      </SectionTwo>

     <SectionTwo>
        <ItemThreeColumn>
         <SecTitleWrapper>
            <H5>Connect</H5>
          </SecTitleWrapper>

          <TextContent>
            <ListItem    
              Title="Twitter" 
              Subtitle="Known as X" 
              Link="https://twitter.com/_atharvapatil" 
            />
            <ListItem    
              Title="LinkedIn" 
              Subtitle="Lets connect" 
              Link="https://www.linkedin.com/in/atharvaabhaypatil/" 
            />
            <ListItem    
              Title="Medium" 
              Subtitle="Read thoughts" 
              Link="https://atharva-patil.medium.com/" 
            />
            <ListItem    
              Title="Github" 
              Subtitle="Code n play" 
              Link="https://github.com/atharvapatil" 
            />
            <ListItem    
              Title="ADPlist" 
              Subtitle="Why is design?" 
              Link="https://adplist.org/mentors/atharva-patil" 
              
            />
          </TextContent>
        </ItemThreeColumn>  
      </SectionTwo>

      

            {/* <VerticalFlex>
              <ConnectLink
                href="https://www.annaohgood.com/recipecontroller"
                target="blank"
              >
                Recipe Controller | ITP Winter show 2019
              </ConnectLink>
              <Detail>
                Project by Anna Oh built using a iOS Arduino bluetooth library I made to create haptic interactions
              </Detail>
            </VerticalFlex> */}
    </>
  )
}


const Section = styled.section`
  padding-top: 8rem;
  padding: 7rem 1rem 2rem 1rem;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-top: 2rem 1.5rem;
  }
`

const SectionTwo = styled.section`
  margin: 0 auto;
  margin-top: 2rem;
  padding: 0 1rem;

  @media (max-width: 768px) {
    padding: 0 1.25rem;
  }
`

const ALink = styled(A)`
  font-size: 1rem;

  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

const ItemThreeColumn = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 2rem;
  padding: 1rem 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

`

const ImageWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  filter: grayscale(1);
  transform: rotate(0.5deg);
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  :hover{
    filter: grayscale(0) hue-rotate(15deg) contrast(0.9);
    /* transform: rotate(0); */
    transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`
const SecTitleWrapper = styled.div`
  margin-top: 1rem;
`